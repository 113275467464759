<template>
  <v-row justify="center">
    <v-dialog
      v-model="dashboardEditStatusDialog"
      width="900px"
      persistent
    >
      <template v-slot:activator="{ on, attrs }">
            <v-btn
            color="primary"
            text
            v-bind="attrs"
            v-on="on"
            small
            >
            <v-icon>mdi-pencil</v-icon>
            </v-btn>
      </template>
        <v-card>
            <v-card-title>
            <span class="text-h5" id="dialog_title">Update Status</span>
            </v-card-title>
            <v-card-text>
                <v-container>
                    <v-row>
                        <v-col>
                            <h4 class="mb-2">Title:</h4>
                            <v-text-field
                                placeholder="New Title"
                                auto-grow
                                v-model="newTitle"
                                outlined
                            ></v-text-field>
                        </v-col>
                    </v-row>
                    <h4 class="mb-2">Description:</h4>
                    <v-row>
                        <v-col>
                            <v-textarea
                                auto-grow
                                outlined
                                v-model="newDesc"
                            ></v-textarea>
                        </v-col>
                    </v-row>
                    <v-row>
                      <v-col>
                        <section>
                          <v-btn
                            v-if="newTitle && newDesc"
                            class="mr-4"
                            color="primary"
                            style="float:right;"
                            @click="update"
                            >
                            <b>Update</b>
                          </v-btn>
                          <v-btn
                            v-else
                            class="mr-4"
                            color="primary"
                            style="float:right;"
                            disabled
                            >
                            <b>Update</b>
                          </v-btn>
                        </section>
                        <section >
                          <v-btn
                            class="mr-4"
                            outlined
                            color="primary"
                            style="float:left;"
                            @click="cancel"
                            >
                            <b>Cancel</b>
                          </v-btn>
                        </section>
                      </v-col>
                    </v-row>
              </v-container>
          </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
  export default {
    props: ["item"],
    async created () {
        this.newTitle = this.item.title
        this.newDesc = this.item.description
    },
    data () {
      return {
        newTitle: null,
        newDesc: null,
        dashboardEditStatusDialog: false,
      }
    },
    methods:{
        cancel(){
          this.dashboardEditStatusDialog = false
        },
         async update(){
            
            const payload = {
                id: this.item.id,
                finalPayload : {
                    title: this.newTitle,
                    description: this.newDesc
                }
            }
            console.log(payload)
          const res = await this.$store.dispatch('salesProspecting/doUpdateStatus', {payload})
            if (res.status === 200){
                this.$store.dispatch('app/setSnackBar',{'status': true,'message':'Updated Successfully!','messagetype':'success'});
                this.dashboardEditStatusDialog = false
                location.reload();
            }
        },
    },
  }
</script>

<style scoped>
    #dialog_title{
        color: rgb(20, 20, 137);
        font-weight: 600;
    }
</style>